<template>
  <b-sidebar
      :visible="isEditNetworkPractiseSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg b-sidebar > .b-sidebar-body"
      @change="(val) => $emit('update:is-edit-network-practise-sidebar-active', val)"

  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Edit Network Practise
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <b-card no-body>

      <b-row class="">
      <b-col class=" mx-1  " cols="12" xl="11">
          <validation-observer ref="EditPcnInfoForm">
            <b-form @submit.prevent>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                  label="Name"
                  label-for="h-name"
              >
                 <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required|pcn_name_length"
                >
                  <b-form-input id="h-name"
                                v-model="form.name"
                                class="mb-1"
                                placeholder="Name"

                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col cols="12" md="12">
              <b-form-group
                  label="Address 1"
                  label-for="h-address"
              >
                 <validation-provider
                    #default="{ errors }"
                    name="Address"
                    rules="required|address"
                >

                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="icon-MapPin"
                      v-model="form.address"
                      placeholder="Address"
                  />
                </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group
                  label="Address 2"
                  label-for="h-address"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Address"
                    rules="required|address"
                >

                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MapPinIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="icons-MapPin"
                        v-model="form.address_2"
                        placeholder="Address"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


              <b-col cols="12" md="12">
              <b-form-group
                  label="City"
                  label-for="h-city"
              >
                <validation-provider
                    #default="{ errors }"
                    name="City"
                    rules="required|name_length"
                >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="icon-MapPis"
                      v-model="form.city"
                  />
                </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group
                  label="Postal Code"
                  label-for="h-postalCode"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Postal Code"
                    rules="required|postal_code"
                >
                  <b-form-input
                      id="icons-phone"
                      v-model="form.postal_code"
                      placeholder="e.g.PR8 8UW"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group
                  label="PCN Type"
                  label-for="h-pcnType"
              >
                <validation-provider
                    #default="{ errors }"
                    name="PCN Type"
                    rules="required"
                >
                <v-select
                    placeholder="Select"
                    v-model="form.type_id"
                    :reduce="name => name.id"
                    label="name"
                    :options="options"
                />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

<!--            <b-col cols="12" md="12">-->
<!--              <b-form-group-->
<!--                  label="Telephone"-->
<!--                  label-for="h-telephone"-->
<!--              >-->
<!--                &lt;!&ndash; <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="Telephone"-->
<!--                    rules="required|tel|min_tel|max_tel"-->
<!--                > &ndash;&gt;-->
<!--                <b-input-group class="input-group-merge">-->
<!--                  <b-input-group-prepend is-text>-->
<!--                    <feather-icon icon="PhoneIcon"/>-->
<!--                  </b-input-group-prepend>-->
<!--                  <b-form-input-->
<!--                      id="icons-phone"-->
<!--                      v-model="form.phone_number"-->
<!--                      placeholder="(+44)"-->
<!--                  />-->
<!--                </b-input-group>-->
<!--                  &lt;!&ndash; <small class="text-danger">{{ errors[0] }}</small>-->
<!--                </validation-provider> &ndash;&gt;-->
<!--              </b-form-group>-->
<!--            </b-col>-->
            <!-- Email -->

<!--            <b-col cols="12" md="12">-->
<!--              <b-form-group-->
<!--                  label="Email"-->
<!--                  label-for="h-email"-->
<!--              >-->
<!--                &lt;!&ndash; <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="Email"-->
<!--                    rules="required|emailTest"-->
<!--                > &ndash;&gt;-->
<!--                <b-input-group class="input-group-merge">-->
<!--                  <b-input-group-prepend is-text>-->
<!--                    <feather-icon icon="MailIcon"/>-->
<!--                  </b-input-group-prepend>-->
<!--                  <b-form-input-->
<!--                      id="icons-mail"-->
<!--                      v-model="form.email"-->
<!--                      placeholder="example@.com"-->
<!--                  />-->
<!--                </b-input-group>-->
<!--                  &lt;!&ndash; <small class="text-danger">{{ errors[0] }}</small>-->
<!--                </validation-provider> &ndash;&gt;-->
<!--              </b-form-group>-->
<!--            </b-col>                 -->


            <!-- submit and reset -->
            <b-col md="12">
<!--              <pre>{{pcnData}}</pre>-->
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  type="submit"
                  variant="primary"
                  @click="profileUpdate()"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="hide"
              >
         <span class="text-secondary">
          Cancel
         </span>
              </b-button>
            </b-col>
          </b-row>
            </b-form>
          </validation-observer>

        </b-col>
      </b-row>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>

import {
  BAlert,
  BButton,
  BCard,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BSidebar,
  BTab,
  BTabs,
  BCol,
  BRow
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import connectionRequests from '@/apis/modules/connectionRequests'


export default {

  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BCard,
    BOverlay,
    BCol,
    BRow,
    BInputGroupPrepend,



    ValidationProvider,
    ValidationObserver,
    BCardActions
  },
  directives: {
    BCardActions,
    Ripple
  },

  props: {
    isEditNetworkPractiseSidebarActive: {
      type: Boolean,
      required: true
    },

    pcnData: {
      type: Object,
      required:true
    }
  },

  data () {
    return {
      // name_length,
      // pcn_name_length,
      // pcn_address_length,
      options:[],
      sideBArLoading: false,
      form: {
        name: '',
        address: '',
        address_2:'',
        email: '',
        phone_number: '',
        city: '',
        postal_code: '',
        type_id: '',
        type_name: ''
      }
    }
  },

  watch: {
    pcnData(val) {
      this.form.name = val.name;
          this.form.address = val.address;
          this.form.address_2 = val.address_2 ? val.address_2:'';
          this.form.email = val.owner.email;
          this.form.phone_number = val.owner.phone_number;
          this.form.city = val.city;
          this.form.postal_code = val.postal_code;
          this.form.type_id = val.type.id;
    },
  },

  methods: {
    async pcnTypeList (){
      try {

        this.options =  (await connectionRequests.getPcnTypes()).data.data

      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

   async profileUpdate () {
     let id = this.pcnData.id
     if (await this.$refs.EditPcnInfoForm.validate()) {
       try {
         const Response = await connectionRequests.updatePractice(id, this.form)
         if (Response.data) {
           this.showSuccessMessage('PCN Details Updated Successfully')
         }
         this.toggle()
         this.$emit('refreshData')
       } catch (e) {
         this.convertAndNotifyError(e);
       }
     }
    },
    toggle () {
      this.$emit('toggleEditSideBars')
    },

    // cancel () {
    //   this.toggle()
    // },

    resetForm () {
    }
  },
  mounted () {
    this.pcnTypeList()
  }

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

//.b-sidebar > .b-sidebar-body {
//  flex-grow: 1;
//  height: 100%;
//  overflow-y: initial;
//}

</style>
<style lang="scss" scoped>
</style>

