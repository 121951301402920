<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isSendConnectionRequestSideBarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Send Connection Request
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="cancel()"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >

<!--        <label class="font-small-3 font-weight-bold pt-1 px-2">Select from the list of existing practices</label>-->
<!--        <b-col  cols="12" md="12" class="pb-0 px-3 mt-1">-->
<!--          <b-input-group class="input-group-merge mb-1">-->
<!--            <b-input-group-prepend is-text>-->
<!--              <feather-icon icon="SearchIcon" />-->
<!--            </b-input-group-prepend>-->
<!--            <b-form-input-->
<!--                v-model="filters['name']"-->
<!--                placeholder="Search" />-->
<!--          </b-input-group>-->
<!--        </b-col>-->


      <label class="font-small-3 font-weight-bold pt-1 px-2">Select from the list of existing practices</label>
          <b-col  cols="12" md="12" class="pb-0 px-3 mt-1">
            <b-input-group class="input-group-merge mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                  v-model="filters['name']"
                  id="searchnew"
                  placeholder="Search" />
            </b-input-group>
          </b-col>

        <!-- Form -->
        <b-form
            class="px-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <b-overlay :show="sideBarLoading" rounded="sm">
            <b-table
                id="connection_requests_table"
                ref="table"
                :current-page="currentPage"
                :fields="fields"
                :items="getPractices"
                :per-page="pagination.perPage"
                :selectOnCheckboxOnly="true"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                class="custom_align mobile_table_css bank-tbl"
                hover
                responsive
            >

              <template #cell(selected)="data">
                <b-form-checkbox class="p-0 m-0" name="check_box" @change="addThisToSelectedList(data.item)" ></b-form-checkbox>
              </template>
            </b-table>
          </b-overlay>

          <b-row>

            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
              <span v-if="pagination.totalRows !==0"
                    class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{
                  pagination.totalRows
                }} entries</span>
            </b-col>

            <!-- Pagination -->

            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

              <b-pagination
                  v-model="currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item">

                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>

              </b-pagination>

            </b-col>
          </b-row>

          <div class="d-flex mt-3">
            <!--            <pre>{{selectedConnections}}</pre>-->
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="selectedConnections.length === 0 || hide_btn"
                type="submit"
                variant="primary"
                @click="inviteViaPractiseId()"
            >
              Send
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1"
                type="button"
                variant="outline-white"
                @click="cancel()"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
      <invitePractice ref="invitePracticeModel"/>
    </template>
  </b-sidebar>
</template>
<!--his.toggle()-->
<!--        this.$emit('refreshData')-->
<script>
import {tasks} from './todoTO_BE_REMOVED'
import connectionRequests from "@/apis/modules/connectionRequests"


import {
  BCol,
  BRow,
  BTable,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend, BOverlay,
  BPagination
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {alphaNum, email, required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import InvitePractice from '@/views/networks/sidebars/InvitePractice'
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import MomentMixin from "@/mixins/MomentMixin";
import Filter from "@/mixins/FilterMixin";
import settings from '@/apis/modules/settings'
export default {
  components: {
    BCol,
    BRow,
    BTable,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BInputGroupPrepend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    InvitePractice,
    BOverlay,
    BPagination
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isSendConnectionRequestSideBarActive',
    event: 'update:is-send-connection-request-side-bar-active'
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  props: {
    isSendConnectionRequestSideBarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      hide_btn: true,
      sideBarLoading:false,
      selectedConnections: [],
      filters:{},
      filter: null,
      filterOn: [],
      selectedCheck: true,
      required,
      alphaNum,
      email,
      start_date: '',
      end_date: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 50
      },
      fields: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'name',
          label: ' NAME'
        },
        {
          key: 'location',
          label: 'LOCATION'
        }
      ],
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc'
    }
  },
  methods: {
    toggle() {
      this.ids = {}
      this.isSendConnectionRequestSideBarActive = false
      // this.selected = []
      // this.filters = []
    },
    showModel(){
      this.$refs.invitePracticeModel.openModal();
    },
    addThisToSelectedList (item) {
      if (_.find(this.selectedConnections, {'id': item.id})) {

        _.remove(this.selectedConnections, function (e) {
          return e.id === item.id
        })
      } else {
        this.selectedConnections.push(item)
      }
      this.hide_btn = this.selectedConnections.length === 0
    },
    async getPractices() {
      const response = await connectionRequests.getNonConnectedOrNonInvitedPractises(this.currentPage, 15,this.filterQuery)
      const dataArray = response.data.data.map((x) => ({
        id:x.id,
        name:x.name,
        location:x.address
      }))
      const paginationResponse = response.data.meta
      this.currentPage = paginationResponse.current_page
      this.pagination.totalRows = paginationResponse.total
      this.pagination.perPage = paginationResponse.per_page
      this.pagination.from = paginationResponse.from
      this.pagination.to = paginationResponse.to
      var checkboxes = document.getElementsByName('check_box')
      for (var checkbox of checkboxes) {
        checkbox.checked = false
         this.$emit('refreshData')
      }
      return dataArray
    },
    cancel () {
      this.toggle()
      this.$emit('refreshData')
    },

    resetForm () {
      this.toggle()
      this.$emit('refreshData')
    },
    async inviteViaPractiseId () {
      try {
        this.sideBarLoading = true
        const data = this.selectedConnections.map((x) => (
            x.id
        ))
        await connectionRequests.sendInvitationsViaPractiseId({ids: data})
        // this.toggle()


        // this.showSuccessMessage('Service Added To Practice ')

        this.showSuccessMessage('Connection Request Sent Successfully')

        this.$refs.table.refresh()
        this.toggle()
        this.$emit('refreshData')
        this.sideBarLoading = false
        this.selectedConnections = []
        // this.selectedConnections.length === 0
      } catch (error) {
        this.convertAndNotifyError(error)
        this.sideBarLoading = false
      }
    },

    // cancel (){
    //   this.$refs.table.refresh()
    //   this.selectedConnections = []
    //   this.selectedConnections.length === 0
    //   this.filters= [],
    //   this.$emit('toggleSideBars')
    // },

    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
  },
  async mounted() {
  },
  watch: {
    isSendConnectionRequestSideBarActive (val) {
      if (val) this.getPractices()
    },
    selected (val) {
      // console.log(val)
    }
  },
  setup(props, {emit}) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: ''
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-location-sidebar-active', false)
          })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetuserData)
    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
//.draggable-task-handle {
//  position: absolute;
//  left: 8px;
//  top: 50%;
//  transform: translateY(-50%);
//  visibility: hidden;
//  cursor: move;
//  .todo-task-list .todo-item:hover & {
//    visibility: visible;
//  }
//}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>

