<template>
  <div>
      <b-row class="mt-n1 ml-0 mr-0">
        <!-- <b-overlay :show="tableLoading" rounded="sm"> -->

               <!-- Left side Icons -->

          <b-col cols="11"  class="pb-2 pt-2">
            <b-overlay :show="tableLoading" rounded="sm">

            <div class="row">



                <!-- <div class="d-flex align-items-center mr-3 mb-1">

                    <b-avatar variant="light-primary" rounded>
                        <feather-icon icon="GlobeIcon" size="18"/>
                    </b-avatar>

                   <div class="ml-1">
                     <h4 class="mb-0 font-weight-bolder "> 10 </h4>
                     <small>Total Network Practices </small>
                    </div>
                </div> -->


                 <div class="d-flex align-items-center mr-3 mb-1">

                    <b-avatar variant="light-success" rounded>
                        <feather-icon icon="LinkIcon" size="18" />
                    </b-avatar>

                   <div class="ml-1">
                      <h4 class="mb-0 font-weight-bolder"> {{summary.connected_practices}} </h4>
                      <small>Connected Practices</small>
                   </div>
                 </div>

                 <div class="d-flex align-items-center mr-3 mb-1">

                    <b-avatar variant="light-primary" rounded >
                        <feather-icon icon="MailIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">

                      <h4 class="mb-0 font-weight-bolder">{{summary.invited_practices}}</h4>
                      <small>Invited Practices</small>
                    </div>
                 </div>

                 <div class="d-flex align-items-center mr-3 mb-1">

                    <b-avatar variant="light-warning" rounded >
                       <feather-icon icon="ClockIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">
                        <h4 class="mb-0 font-weight-bolder"> {{summary.connection_requests}} </h4>
                        <small>Pending Acceptance</small>
                    </div>
                </div>

<!--                <div class="d-flex align-items-center mr-3 mb-1">-->
<!--                   <b-avatar variant="light-danger" rounded> -->
<!--                      <feather-icon icon="RepeatIcon" size="18" />-->
<!--                    </b-avatar>-->

<!--                    <div class="ml-1">-->
<!--                        <h4 class="mb-0 font-weight-bolder"> 3 </h4>-->
<!--                        <small>Connection Request</small>-->
<!--                    </div>-->
<!--                </div>-->
<!-- {{summary}} -->

                <!-- <div class="d-flex align-items-center mr-3 mb-1">
                   <b-avatar variant="light-primary" rounded>
                      <feather-icon icon="UsersIcon" size="18" />
                    </b-avatar>

                    <div class="ml-1">
                        <h4 class="mb-0 font-weight-bolder"> 437 </h4>
                        <small>Total Staff</small>
                    </div>
                </div> -->

            </div>
            </b-overlay>
          </b-col>

         <!-- Right side Downloading button -->

          <!-- <b-col cols="1"  class="pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ">
            <b-row >
                <div class="d-flex justify-content-start  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto">

                      <b-button
                            size="23"
                            variant="outline-white"
                            class="btn-icon bg-white text-primary" >

                           <feather-icon
                              size="18"
                              icon="DownloadIcon"
                            />
                      </b-button>
                </div>
            </b-row>
          </b-col> -->
        <!-- </b-overlay>   -->
      </b-row>
  </div>
</template>

<script>

import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BOverlay} from 'bootstrap-vue'

import connectionRequests from "@/apis/modules/connectionRequests";


export default {
  name: 'profile-image-bar',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BOverlay
  },
  data() {
    return {
      summary:{},
      tableLoading: false
    }
  },

  methods: {
    async getSummary() {
      try {
        this.tableLoading = true
        const response = await connectionRequests.getSummary()
        // console.log('Summary',response.data.data)
        this.summary = response.data.data
        this.tableLoading = false
      }catch (error){
        // this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    }
  },

  async mounted() {
        await this.getSummary()
  }
}
</script>

<style scoped>

</style>
