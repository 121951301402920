<template>
  <div>
    <b-card class="mb-0" no-body>
      <div class="">
        <b-row>
          <b-col cols="12" md="4" class="" v-if="noDataTable !== 0">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                  icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="icons-search"
                  v-model="filters['search']"
                  placeholder="Search"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- <b-col class="flex-float-right" cols="12" md="">
            <b-form-group>
              <b-button
                  class="btn-sm-block float-right"
                  variant="primary"
              >
                <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                />
                <span class="align-middle">Send Connection Request</span>
              </b-button>
            </b-form-group>
          </b-col> -->
        <b-col cols="12" v-if="noDataTable === 0" class="text-center">
<!--        <span class="font-weight-bolder" style="font-size: larger; color: #949494;">No data for preview</span>-->
        <div class="empty-state">
          <img class="img-fluid" src="@/assets/images/pages/Shifts.png" alt="">
          <p class="txt-grey mt-2">No Connection Requests</p>
        </div>
      </b-col>
          <b-col cols="12" v-if="noDataTable !== 0">
            <b-overlay :show="tableLoading" rounded="sm">
              <b-table
                ref="refUserListTable"
                :current-page="currentPage"
                :fields="fields"
                :items="getConnectionRequestsRaisedbyPractises"
                class="position-relative mobile_table_css"
                empty-text="No matching records found"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                hover
                primary-key="id"
                responsive
                show-empty
                @row-clicked="myRowClickHandler"
              >

                <template #cell(action)="data">
                  <div class="button bg-non">
                    <b-button
                      size="23"
                      variant="outline-white"
                      class="btn-icon bg-white text-primary m-1"
                      v-b-tooltip.hover.top="'Approve'"
                      @click="approveConnectionRequest(data.item.id)"
                    >
                      <feather-icon icon="CheckSquareIcon" size="18" />
                    </b-button>
                    <b-button
                      size="23"
                      variant="outline-white"
                      class="btn-icon bg-white text-danger m-1"
                      v-b-tooltip.hover.top="'Reject'"
                      @click="rejectConnectionRequest(data.item.id)"
                    >
                      <feather-icon
                        icon="XSquareIcon"
                        size="18"
                        variant="outline-danger"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </b-col>
          <b-col
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
            cols="12"
            sm="6"
            v-if="noDataTable !== 0"
          >
            <span class="text-muted" v-if="pagination.totalRows !== 0"
              >Showing {{ pagination.from }} to {{ pagination.to }} of
              {{ pagination.totalRows }} entries</span
            >
          </b-col>

          <b-col
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
            cols="12"
            sm="6"
            v-if="noDataTable !== 0"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

        <!--                pagination-->
        <!-- <div class="mx-1 mb-2"> -->
        <!-- <b-row> -->

        <!-- <b-col -->
        <!-- class="d-flex align-items-center justify-content-center justify-content-sm-start" -->
        <!-- cols="12" -->
        <!-- sm="6" -->
        <!-- > -->
        <!-- <span class="text-muted">Showing 1 to 3 of 3 entries</span> -->
        <!-- </b-col> -->
        <!-- Pagination -->
        <!-- <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            > -->

        <!-- <b-pagination
                  v-model="currentPage"
                  :per-page="10"
                  :total-rows="3"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              > -->
        <!-- <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template> -->
        <!-- <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination> -->

        <!-- </b-col> -->

        <!-- </b-row> -->
        <!-- </div> -->
      </div>
    </b-card>
  </div>
</template>

<script>
import connectionRequests from "@/apis/modules/connectionRequests";
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "map-list",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,

    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data: function () {
    return {
      noDataTable:'',
      tableLoading: false,
      currentPage: 1,
      isSendConnectionRequestSidebarActive: false,
      filters: {},
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "address",
          // sortable: true,
        },
        {
          key: "city",
          // sortable: true,
        },
        {
          key: "postal_code",
          // sortable: true,
        },
        {
          key: "telephone",
          // sortable: true,
        },
        {
          key: "email",
          // sortable: true,
        },
        // {
        //   key: 'staff',
        //   sortable: true
        // },
        {
          key: "date_request_sent",
          sortable: true,
        },
        {
          key: "action",
          label: "Actions",
          // sortable: true,
        },
      ],
      // items: [{
      //   'name': 'Thackeray',
      //   'address': 'America/Lima',
      //   'city': 5446,
      //   'postal_code': 'mgariff0@nbcnews.com',
      //   'telephone': '+44 7911 123456',
      //   'email': 'sample@gmail.com',
      //   'date_request_sent': 'Mar 7, 2021, 15:03',
      //   'status': 'Connected'
      // }, {
      //   'name': 'Mosinee',
      //   'address': 'America/Bogota',
      //   'city': 9059,
      //   'postal_code': 'gmattosoff1@jugem.jp',
      //   'telephone': '+44 7911 123456',
      //   'email': 'sample@gmail.com',
      //   'date_request_sent': 'Mar 7, 2021, 15:03',
      //   'status': 'Pending'
      // },  {
      //   'name': 'Florence',
      //   'address': 'Europe/Simferopol',
      //   'city': 9786,
      //   'postal_code': 'ckilgrove3@bing.com',
      //   'telephone': '+44 7911 123456',
      //   'email': 'sample@gmail.com',
      //   'date_request_sent': 'Mar 7, 2021, 15:03',
      //   'status': 'Connected'
      // },
      // ]
    };
  },
  methods: {
    toggleSideBars() {
      this.isSendConnectionRequestSidebarActive =
        !this.isSendConnectionRequestSidebarActive;
    },
    myRowClickHandler(record, index) {},

    async getConnectionRequestsRaisedbyPractises() {
      try {
        this.tableLoading = true;

        const Response = await connectionRequests.getConnectionRequests(this.currentPage, 10, this.filterQuery);

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.practice.name,
          address: x.practice.address,
          city: x.practice.city,
          postal_code: x.practice.postal_code,
          telephone: x.practice.phone_number ? x.practice.phone_number : "-",
          email: x.practice.email,
          date_request_sent: this.momentFormat(
            x.created_at,
            "ddd DD MM YYYY"
          ),
        }));
        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.tableLoading = false;
        this.$emit('noOfConnectionRequests',this.pagination.totalRows)
        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },


    async approveConnectionRequest(id){
      this.tableLoading = true
      this.$swal({
        title: 'Are you sure you want to accept this request?',
        imageUrl: require('@/assets/images/icons/publish.png'),
        imageWidth: 80,
        imageHeight: 80,
        text: '',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
          if(result.value){
            try{
              this.tableLoading = true
              await connectionRequests.connectionRequestAccept(id)
              this.$refs.refUserListTable.refresh()
              this.tableLoading = false
              this.showSuccessMessage('Request accepted successfully!')
              this.$emit('dataRefresh')
            }catch(error){
              this.convertAndNotifyError(error)
              this.tableLoading = false
            }
          }
        })
      this.tableLoading = false
    },


    async rejectConnectionRequest(id){
      this.tableLoading = true
      this.$swal({
        title: 'Are you sure you want to reject this request?',
        text: '',
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
           if (result.value) {
              try{
                this.tableLoading = true
                await connectionRequests.connectionRequestReject(id)
                this.$refs.refUserListTable.refresh()
                this.tableLoading = false
                this.showSuccessMessage('Request rejected successfully!')
                this.$emit('dataRefresh')
              }catch(error){
                this.convertAndNotifyError(error)
                this.tableLoading = false
              }
           }
        })

      this.tableLoading = false

    },

    filterQueryUpdate () {
      this.$refs.refUserListTable.refresh()
    },
  },

  mounted() {
  },
};
</script>

<style scoped>
.button {
  display: flex;
  flex-direction: row;
}
</style>
