<template>
  <div>
<!--    <router-view></router-view>-->
    <b-card class="mb-0" no-body>
      <div class="">
        <b-row>
          <b-col cols="12" md="4" class="">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                  icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="icons-search"
                  v-model="filters['search']"
                  placeholder="Search"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- <b-col class="flex-float-right" cols="12" md="">
            <b-form-group>
              <b-button
                  class="btn-sm-block float-right"
                  variant="primary"
              >
                <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                />
                <span class="align-middle">Send Connection Request</span>
              </b-button>
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
          <b-overlay :show="tableLoading" rounded="sm">
            <b-table
                ref="refUserListTable"
                :current-page="currentPage"
                :fields="fields"
                :items="getPractises"
                :per-page="10"
                class="position-relative mobile_table_css" empty-text="No matching records found"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                hover
                primary-key="id"
                responsive
                show-empty
                @row-clicked="myRowClickHandler"
            >
              <template #cell(name)="data">

                <!-- avatar group -->
                    <span >{{data.item.name}}
                    </span>
                     <b-badge v-if="data.item.status ==='Connected'" v-b-tooltip.hover.top="'Connected Practice'"
                              class="ml-1"
                              variant="light-primary">
                       <feather-icon class="" icon="LinkIcon"/>
                     </b-badge>

              </template>

<!--              <template #cell(staff)="data">-->

<!--                &lt;!&ndash; avatar group &ndash;&gt;-->
<!--                <b-avatar-group-->
<!--                    size="34px"-->
<!--                >-->
<!--                  <b-avatar-->
<!--                      v-for="avatar in [5,7,8,9]"-->
<!--                      :key="avatar.avatar"-->
<!--                      v-b-tooltip.hover.bottom="avatar.fullName"-->
<!--                      :src="require(`@/assets/images/portrait/small/avatar-s-${avatar}.jpg`)"-->
<!--                      class="pull-up"-->
<!--                  />-->
<!--                  <h6 class="align-self-center cursor-pointer ml-1 mb-0">-->
<!--                    +12-->
<!--                  </h6>-->
<!--                </b-avatar-group>-->

<!--              </template>-->

              <template #cell(status)="data">
                <b-badge v-if="data.item.status ==='Connected'"

                    :variant="'light-success'"
                    class="text-capitalize"
                >
                  Connected
                </b-badge>
                <b-badge v-else

                    :variant="'light-warning'"
                    class="text-capitalize"
                >
                  Pending
                </b-badge>
              </template>
            </b-table>
            </b-overlay>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
      <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
    </b-col>

    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

      <b-pagination
          v-model="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          class="mb-0 mt-1 mt-sm-0 "
          first-number
          last-number
          next-class="next-item"
          prev-class="prev-item">

        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18"/>
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18"/>
        </template>
      </b-pagination>
    </b-col>
   </b-row>

        <!--                pagination-->
        <!-- <div class="mx-1 mb-2">
          <b-row>

            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6" -->
            <!-- >  -->
              <!-- <span class="text-muted">Showing 1 to 10 of 10 entries</span> -->
            <!-- </b-col>-->
            <!-- Pagination -->
            <!-- <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6" -->
            <!-- >  -->

              <!-- <b-pagination
                  v-model="currentPage"
                  :per-page="10"
                  :total-rows="10"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination> -->

            <!-- </b-col> -->

          <!-- </b-row> -->
        <!-- </div> -->
      </div>
    </b-card>



  </div>
</template>

<script>
import connectionRequests from "@/apis/modules/connectionRequests";
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'


import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'map-list',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,

    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data: function () {
    return {
      tableLoading:false,
      currentPage: 1,
      isSendConnectionRequestSidebarActive: false,
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",
      filters: {},
      filter: null,
      filterOn: [],
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },

      fields: [
        {
          key: 'name',
          sortable: true,
          tdClass: 'nameFieled'
        },
        {
          key: 'address',
        },
        {
          key: 'city',
        },
        {
          key: 'postal_code',
        },
        {
          key: 'telephone',
        },
        {
          key: 'email',
        },
        // {
        //   key: 'staff',
        // },
        // {
        //   key: 'status',
        //   sortable: true
        // }
      ],
    }
  },
  methods: {
    toggleSideBars() {
      this.isSendConnectionRequestSidebarActive = !this.isSendConnectionRequestSidebarActive

    },
    myRowClickHandler(record, index) {
      this.$router.push({name:'networks.single',params:{id:record.practiceId}})
    },

   async getPractises() {
      try {
        this.tableLoading = true;
        const Response = await connectionRequests.getNetworkPractises(this.currentPage,10, this.filterQuery);
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          practiceId:x.practice_id,
          name: x.practice.name,
          address: x.practice.address,
          city: x.practice.city,
          postal_code: x.practice.postal_code,
          telephone: x.practice.phone_number ? x.practice.phone_number : "-",
          email: x.practice.email

        }));
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.tableLoading = false;
        this.$emit('noOfConnectedPractices',this.pagination.totalRows)
        return dataArray;
      } catch (e) {
        this.convertAndNotifyError(e)
        this.tableLoading = false;
      }
    },
    filterQueryUpdate () {
      this.$refs.refUserListTable.refresh()
    },
  },

 async mounted() {
      await this.getPractises()
  }
}
</script>

<style scoped>

.nameFieled{
  max-width: 10px;
}
</style>
