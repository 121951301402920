<template>
  <div>
    <b-modal
        id="modal-center"
        v-model="showInvite"
        no-close-on-backdrop

        centered
        hide-footer
        size="md"
    >

<!--      <b-card class="card text-center pr-4 pl-4">-->
<!--        <b-card-body >-->
            <div class="text-center">
              <svg width="58" height="80" viewBox="0 0 58 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.1392 8.371H32.5568V5.78924C32.5568 5.22412 32.096 4.76172 31.5296 4.76172H29.6576C29.0912 4.76172 28.6304 5.22412 28.6304 5.78924V8.371H26.048C25.4816 8.371 25.0208 8.83372 25.0208 9.39884V11.2705C25.0208 11.8363 25.4816 12.2984 26.048 12.2984H28.6304V14.8808C28.6304 15.4459 29.0912 15.9083 29.6576 15.9083H31.5296C32.096 15.9083 32.5568 15.4459 32.5568 14.8808V12.2984H35.1392C35.7056 12.2984 36.1696 11.8363 36.1696 11.2705V9.39884C36.1696 8.83372 35.7056 8.371 35.1392 8.371Z" fill="#1B9AAA"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9776 57.9972H43.344V55.3796H14.9776V57.9972ZM40.128 77.6049H43.3824V60.353H40.128V77.6049ZM30.3392 77.6049H37.7728V60.353H30.3392V77.6049ZM20.6282 77.6049H27.984V60.353H20.6282V77.6049ZM15.0176 77.6049H18.2717V60.353H15.0176V77.6049ZM55.7856 77.6049H52.6624V70.9252C52.6624 70.2747 52.1344 69.7473 51.4848 69.7473C50.8352 69.7473 50.3072 70.2747 50.3072 70.9252V77.6049H45.7376V59.5415C45.7376 59.4343 45.7184 59.3326 45.6928 59.2343C45.6928 59.2142 45.6992 59.1953 45.6992 59.1751V54.2017C45.6992 53.5511 45.1712 53.0234 44.5216 53.0234H13.7997C13.1491 53.0234 12.6218 53.5511 12.6218 54.2017V59.1751C12.6218 59.3034 12.6474 59.4241 12.6851 59.5396C12.6698 59.6161 12.6611 59.6951 12.6611 59.7761V78.5665C12.6611 79.2174 13.1888 79.7444 13.8394 79.7444C13.8451 79.7444 13.8506 79.7431 13.8566 79.7428C14.0486 79.8791 14.2826 79.9611 14.5363 79.9611H55.7856C56.4352 79.9611 56.9632 79.4337 56.9632 78.7831C56.9632 78.1323 56.4352 77.6049 55.7856 77.6049Z" fill="#171822"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.44195 22.4428H53.92V19.7334H4.44195V22.4428ZM15.3578 17.3039H43.0016V2.53975H15.3578V17.3039ZM9.35267 77.6047H8.05442V25.0633H50.3072V62.4434C50.3072 63.094 50.8352 63.6214 51.4848 63.6214C52.1344 63.6214 52.6624 63.094 52.6624 62.4434V24.799H55.0976C55.7472 24.799 56.2752 24.2716 56.2752 23.6207V18.5554C56.2752 17.9046 55.7472 17.3772 55.0976 17.3772H45.36V1.36151C45.36 0.710954 44.832 0.183594 44.1792 0.183594H14.1799C13.5293 0.183594 13.0019 0.710954 13.0019 1.36151V17.3772H3.2637C2.61314 17.3772 2.08578 17.9046 2.08578 18.5554V23.6207C2.08578 24.2716 2.61314 24.799 3.2637 24.799H5.69827V77.6047H1.3421C0.691545 77.6047 0.164185 78.1321 0.164185 78.783C0.164185 79.4335 0.691545 79.9609 1.3421 79.9609H9.35267C10.0035 79.9609 10.5306 79.4335 10.5306 78.783C10.5306 78.1321 10.0035 77.6047 9.35267 77.6047Z" fill="#171822"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.8113 11.1081C33.8113 11.1529 33.7729 11.1932 33.7281 11.1932H31.1457C30.6241 11.1932 30.2017 11.6153 30.2017 12.1356V14.7177C30.2017 14.7622 30.1601 14.8025 30.1185 14.8025H28.2433C28.1985 14.8025 28.1601 14.7622 28.1601 14.7177V12.1356C28.1601 11.6153 27.7377 11.1932 27.2161 11.1932H24.6336C24.5888 11.1932 24.5504 11.1529 24.5504 11.1081V9.23641C24.5504 9.19161 24.5888 9.15065 24.6336 9.15065H27.2161C27.7377 9.15065 28.1601 8.72889 28.1601 8.20857V5.62585C28.1601 5.58233 28.2017 5.54105 28.2433 5.54105H30.1185C30.1601 5.54105 30.2017 5.58233 30.2017 5.62585V8.20857C30.2017 8.72889 30.6241 9.15065 31.1457 9.15065H33.7281C33.7729 9.15065 33.8113 9.19161 33.8113 9.23641V11.1081ZM33.7281 7.26585H32.0865V5.62585C32.0865 4.53977 31.2033 3.65625 30.1185 3.65625H28.2433C27.1585 3.65625 26.2752 4.53977 26.2752 5.62585V7.26585H24.6336C23.5488 7.26585 22.6644 8.15001 22.6644 9.23641V11.1081C22.6644 12.1945 23.5488 13.0783 24.6336 13.0783H26.2752V14.7177C26.2752 15.8041 27.1585 16.6873 28.2433 16.6873H30.1185C31.2033 16.6873 32.0865 15.8041 32.0865 14.7177V13.0783H33.7281C34.8129 13.0783 35.6961 12.1945 35.6961 11.1081V9.23641C35.6961 8.15001 34.8129 7.26585 33.7281 7.26585Z" fill="#171822"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6841 38.927C15.0336 38.927 14.5062 39.4543 14.5062 40.1049V41.8326C14.5062 42.4831 15.0336 43.0105 15.6841 43.0105H24.3232C24.9728 43.0105 25.5008 42.4831 25.5008 41.8326V33.1935C25.5008 32.543 24.9728 32.0156 24.3232 32.0156H15.6841C15.0336 32.0156 14.5062 32.543 14.5062 33.1935V34.9219C14.5062 35.5724 15.0336 36.0998 15.6841 36.0998C16.335 36.0998 16.8624 35.5724 16.8624 34.9219V34.3715H23.1456V40.6543H16.8624V40.1049C16.8624 39.4543 16.335 38.927 15.6841 38.927Z" fill="#171822"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3952 40.6543H41.68V34.3715H35.3952V40.6543ZM34.2176 32.0156C33.568 32.0156 33.04 32.543 33.04 33.1935V41.8326C33.04 42.4831 33.568 43.0105 34.2176 43.0105H42.8576C43.5072 43.0105 44.0352 42.4831 44.0352 41.8326V33.1935C44.0352 32.543 43.5072 32.0156 42.8576 32.0156H34.2176Z" fill="#171822"/>
              </svg>


              <!--                <b-avatar variant="light-primary" size="8rem" rounded>-->
<!--                  <feather-icon icon="HomeIcon" size="30" />-->
<!--                </b-avatar>-->

            </div>
      <b-row>
        <b-col cols="1"></b-col>
        <b-col cols="10" class="mb-3">
          <div class="mt-1 mb-1 text-center">
            <div class="">
              <h3 class="font-weight-bolder">Invite Practice</h3>
              <p>Enter medical practice name and email below. We will send an invitation link to the practice</p>
            </div>
          </div>
          <div class="mt-1">
            <div class="">
              <div class="d-flex align-items-center mb-1"><b-form-input
                  id="test"
                  type="number"
                  placeholder="Medical Practice"
              /></div>
              <div class="d-flex align-items-center mb-1"><b-form-input
                  id="standard"
                  type="number"
                  placeholder="Email"
              /></div>
              <div class="d-flex align-items-center "><b-button
                  size=""
                  variant="primary"
                  class="btn-lg btn-block btn"
                  @click="showInvite = false"
              >
                Invite
              </b-button></div>

            </div>
          </div>
        </b-col>
      </b-row>

<!--        </b-card-body>-->
<!--    </b-card >-->

  </b-modal>

  </div>
</template>

<script>

import {
  BAlert,
  BAvatar, BAvatarGroup, BBadge,
  BButton, BButtonGroup,
  BCard, BCardBody,
  BCardHeader,
  BCardText, BCardTitle,
  BCol, BDropdown, BDropdownItem, BFormCheckbox, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupPrepend, BLink, BListGroupItem,
  BMedia, BModal, BOverlay, BPagination,
  BRow,
  BTab, BTable,
  BTabs, VBModal, VBToggle,
  VBTooltip
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VuePerfectScrollbar from "vue-perfect-scrollbar";


export default {

  components: {
    BTabs,
    BCardText,
    BTab,
    flatPickr,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BListGroupItem,
    BCardTitle,
    BFormGroup,
    VBToggle,
    BModal, VBModal, BAlert,
    BButtonGroup,
    BOverlay, BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
  },

  data(){
    return{
        items: '',
        showInvite:false,
        invoiceModalLoading:false,
    }
  },

  mounted(){

  },
  methods:{
     openModal(){
      this.showInvite = true;
   }
  },


}
</script>

<style scoped>

</style>
