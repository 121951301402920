<template>
  <div>
    <b-card class="mb-0" no-body>
      <div class="">
        <b-row>
          <b-col cols="12" md="4" class="">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon 
                  icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="icons-search"
                  v-model="filters['search']"
                  placeholder="Search"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- <b-col class="flex-float-right" cols="12" md="">
            <b-form-group>
              <b-button
                  class="btn-sm-block float-right"
                  variant="primary"
              >
                <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                />
                <span class="align-middle">Send Connection Request</span>
              </b-button>
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-overlay :show="tableLoading" rounded="sm">
            <b-table
                :current-page="currentPage"
                ref="refUserListTable"
                :fields="fields"
                :items="getInvitations"
                :per-page="pagination.perPage"
                class="position-relative mobile_table_css" empty-text="No matching records found"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                hover
                primary-key="id"
                responsive
                show-empty
                @row-clicked="myRowClickHandler"
            >

                <template #cell(action)="data">
                        <b-button
                            size="10"
                            variant="outline-white"
                            class="btn-icon bg-white text-primary  shadow"
                            v-b-tooltip.hover.top="'Send Again'"
                            @click="resendInvitations(data.item.id)"
                        >
                            <feather-icon icon="SendIcon" size="12" class="" style="margin-right: 2px ;margin-top: 2px"/> <span class="">Send Again</span>
                        </b-button>
                </template>
            </b-table>
            </b-overlay>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
      <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
    </b-col>

    <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

       <b-pagination
          v-model="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          class="mb-0 mt-1 mt-sm-0 "
          first-number
          last-number
          next-class="next-item"
          prev-class="prev-item">

        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18"/>
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18"/>
        </template>
      </b-pagination>
    </b-col>
        </b-row>

        <!--                pagination-->
        <!-- <div class="mx-1 mb-2"> -->
          <!-- <b-row> -->

            <!-- <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
            > -->
              <!-- <span class="text-muted">Showing 1 to 2 of 2 entries</span>
            </b-col> -->
            <!-- Pagination -->
            <!-- <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            > -->

              <!-- <b-pagination
                  v-model="currentPage"
                  :per-page="10"
                  :total-rows="2"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              > -->
                <!-- <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template> -->
                <!-- <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination> -->

            <!-- </b-col> -->

          <!-- </b-row> -->
        <!-- </div> -->
      </div>
    </b-card>



  </div>
</template>

<script>

import connectionRequests from "@/apis/modules/connectionRequests";
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'map-list',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,

    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data: function () {
    return {
      tableLoading: false,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'asc',
      filters: {},
      filter: null,
      filterOn: [],
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      isSendConnectionRequestSidebarActive: false,
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'email',
          sortable: true
        },

        {
          key: 'date_invitations_sent',
          sortable: true
        },
        {
          key: 'action',
          label:'Action',
          sortable: true
        }
      ],
      items: [{
        'name': 'Thackeray',
        'email': 'sample@gmail.com',
        'date_invitations_sent': '05/03/2021',
        'status': 'Connected'
      }, {
        'name': 'Mosinee',
        'email': 'sample@gmail.com',
        'date_invitations_sent': '05/03/2021',
        'status': 'Pending'
      },
      ]
    }
  },
  methods: {
    toggleSideBars() {
      this.isSendConnectionRequestSidebarActive = !this.isSendConnectionRequestSidebarActive

    },
    myRowClickHandler(record, index) {

    },

   async getInvitations() {
     try{
       this.tableLoading = true;
       const Response = (await connectionRequests.getInvites(this.currentPage,10, this.filterQuery))
       const dataArray = Response.data.data.map((x) => ({
         id: x.id,
         name: x.practice.name,
         email: x.practice.email,
         date_invitations_sent: this.momentFormat(x.created_at,'ddd DD MM YYYY' )
       }))


       const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
       // this.$refs.refUserListTable.refresh()
        return dataArray
     }catch(error) {
       this.convertAndNotifyError(error);
       this.tableLoading = false;
     }
    },

    refreshInvitationTable() {
      this.$refs.refUserListTable.refresh()
    },

    async resendInvitations(id) {
      try {
        this.tableLoading = true
        const Response = (await connectionRequests.resendInvites(id))
        if (Response.data) {
          this.showSuccessMessage('Connection Request Resent Successfully')
        }
        this.$refs.refUserListTable.refresh()
        this.tableLoading = false
      }catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false
      }
    },
    filterQueryUpdate () {
      this.$refs.refUserListTable.refresh()
    },
  },

  async mounted() {
     await this.getInvitations()
  }
}
</script>

<style scoped>

</style>
